import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sentinel", "scrollContent"];
  static values = {
    url: String
  };

  connect() {
    this.createObserver();
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  createObserver() {
    if (this.observer) {
      this.observer.disconnect();
    }

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.fetchData();
        }
      });
    }, {
      rootMargin: '0px 0px 100px 0px',
      threshold: 0.1
    });

    this.observer.observe(this.sentinelTarget);
  }

  fetchData() {
    if (this.isLoading || !this.urlValue) {
      return;
    }

    this.isLoading = true;
    const nextPage = parseInt(this.element.dataset.page || 1, 10) + 1;

    fetch(`${this.urlValue}?page=${nextPage}`, {
      headers: { Accept: "text/vnd.turbo-stream.html" }
    })
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.text();
      })
      .then(html => {
        Turbo.renderStreamMessage(html);
        this.element.dataset.page = nextPage;
        this.isLoading = false;

        // Check if the sentinel is still in view and trigger again if needed
        if (this.isSentinelInView()) {
          this.fetchData();
        }
      })
      .catch(error => {
        console.error("Error fetching more items:", error);
        this.isLoading = false;
      });
  }

  isSentinelInView() {
    const rect = this.sentinelTarget.getBoundingClientRect();
    return rect.top < window.innerHeight && rect.bottom >= 0;
  }
}
