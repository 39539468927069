import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "toggle"]


  connect() {
    this.moreTextValue = this.toggleTarget.innerHTML
    this.lessTextValue = this.toggleTarget.getAttribute("data-less-text")
    this.open = false
    if (!this.isTruncated()) {
      return this.toggleTarget.style.display = "none"
    } else {
      this.toggleTarget.addEventListener("click", this.toggle.bind(this))
    }
  }

  toggle(event) {
    this.open === false ? this.show(event) : this.hide(event)
  }

  show(event) {
    this.open = true

    const target = event.target
    target.innerHTML = this.lessTextValue
    this.contentTarget.style.setProperty("--read-more-line-clamp", "'unset'")
  }

  hide(event) {
    this.open = false

    const target = event.target
    target.innerHTML = this.moreTextValue
    this.contentTarget.style.removeProperty("--read-more-line-clamp")
  }

  isTruncated() {
    const isTextClamped = elm => elm.scrollHeight > elm.clientHeight
    return isTextClamped(this.contentTarget)
  }
}