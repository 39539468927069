import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit() {
    this.element.requestSubmit()
  }

  updateActionUrlFromFilteredFrameSrc(event) {
    const frame = event.target
    if (frame.id !== this.form.getAttribute("data-turbo-frame")) { return }
    if (frame.src === undefined) { return }

    const frameUrl = new URL(frame.src)
    this.form.action = frameUrl.toString()
  }

  get form() {
    return this.element
  }
}
