import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // This controller is used to delay the submission of the form until all upload fields have been marked as completed.
  // The upload field is the element that is used to track the upload (or creation via endpoint) of an object.
  // Completion is tracked via data attributes on each uploadField.
  // Upload fields are usually hidden fields.
  static targets = [ "uploadField" ]

  static values = {
    requestUploadEventName: String
  }

  deferSubmitUntilUploadsDone(event) {
    if (!this.hasNonUploadedFiles) { return }
    if (!this.hasRequestUploadEventNameValue) { return }
    
    if (!this.isCurrentForm(event.target)) { return }

    event.preventDefault() // if submit@document is caught
    event?.detail?.formSubmission?.stop() // if turbo:submit-start, stop the form submission
    this.submitCurrentFormWhenUploadsCompleted = true

    this.nonUploadedFields.forEach((target) => {
      target.dispatchEvent(new CustomEvent(`${this.requestUploadEventNameValue}`, { bubbles: true, cancelable: true }))
    })
  }

  markFieldAsUploading(event) {
    this.annotateUploadField(event.target, { uploading: true, uploadCompleted: false})
  }

  markFieldAsCompleted(event) {
    this.annotateUploadField(event.target, { uploading: false, uploadCompleted: true})
    this.submitCurrentFormIfAllUploadsCompleted()
  }

  markFieldAsPending(event) {
    this.annotateUploadField(event.target, { uploading: false, uploadCompleted: false})
  }

  annotateUploadField(uploadField, { uploading, uploadCompleted }) {
    uploadField.dataset.uploading = uploading
    uploadField.dataset.uploadCompleted = uploadCompleted
  }
  
  get nonUploadedFields() {
    return this.uploadFieldTargets.filter((f) => {
      return f.dataset.uploading !== "true" && f.dataset.uploadCompleted !== "true"
    })
  }

  get uploadingFields() {
    return this.uploadFieldTargets.filter((f) => {
      return f.dataset.uploading === "true" && f.dataset.uploadCompleted !== "true"
    })
  }

  get completedUploadFields() {
    return this.uploadFieldTargets.filter((f) => {
      return f.dataset.uploading !== "true" && f.dataset.uploadCompleted === "true"
    })
  }

  get hasNonUploadedFiles() {
    return this.nonUploadedFields.length > 0 || this.uploadingFields.length > 0
  }

  get form() {
    if (!this.hasUploadFieldTarget) { return }
    return this.uploadFieldTarget.form
  }

  isCurrentForm(form) {
    return form === this.form
  }

  submitCurrentFormIfAllUploadsCompleted() {
    if (!this.submitCurrentFormWhenUploadsCompleted) { return }

    if (this.hasNonUploadedFiles) { return }
    if (this.uploadingFields.length > 0) { return }

    // add a slight delay in case this is called within the same event lifecycle as the initial button press to submit the form
    window.setTimeout(() => {
      if (this.form.requestSubmit && typeof this.form.requestSubmit === 'function') {
        this.form.requestSubmit()
      } else {
        this.form.submit()
      }
    }, 10)
  }
}