import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["offerId", "offerForm"]

  connect() {
    console.log("StackWizardController connected")
  }

  selectOffer(event) {
    event.preventDefault()

    const { offerId } = event.currentTarget.dataset

    this.offerIdTarget.value = offerId
    Turbo.navigator.submitForm(this.offerFormTarget)
  }
}
