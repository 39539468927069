import { FieldController } from "@bullet-train/fields"

export default class extends FieldController {
  static targets = [ "lengthIndicatorContainer", "lengthIndicatorTemplate" ]
  static classes = [ "error", "maxlengthError" ]
  static values = { 
    maxlength: { type: Number, default: 0 },
    allowBeyondMaxlength: { type: Boolean, default: false }
  }

  connect() {
    super.connect()
    this.updateLengthIndicator()
  }

  acquiesceError(event) {
    this.field.classList.remove(...this.errorClasses)
  }

  updateLengthIndicator() {
    this.updateMaxlengthFromFieldAttribute()
    if (!this.hasMaxlengthValue || this.maxlengthValue === 0) return
    if (!this.hasLengthIndicatorContainerTarget) return
    if (!this.hasLengthIndicatorTemplateTarget) return

    const length = this.field.value.length
    const maxLength = this.maxlengthValue
    const lengthIndicator = this.lengthIndicatorContainerTarget

    if (length > maxLength) {
      lengthIndicator.classList.add(...this.maxlengthErrorClasses)
    } else {
      lengthIndicator.classList.remove(...this.maxlengthErrorClasses)
    }

    lengthIndicator.innerHTML = this.lengthIndicatorTemplateTarget.innerHTML
      .replace(/{{length}}/g, length)
      .replace(/{{maxLength}}/g, maxLength)
  }

  updateMaxlengthFromFieldAttribute() {
    const fieldMaxLength = this.field.getAttribute('maxlength')
    if (fieldMaxLength) {
      this.maxlengthValue = parseInt(fieldMaxLength, 10)
    }
    if (this.allowBeyondMaxlengthValue) {
      this.field.removeAttribute('maxlength')
    }
  }
}