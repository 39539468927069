import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["numbered"]

  static values = {
    templateString: String,
    placeholderToken: String,
    startAt: {
      type: Number,
      default: 1
    }
  }

  connect() {
    this.renumber()
  }

  renumber() {
    let index = this.startAtValue
    this.numberedTargets.forEach((numbered) => {
      if (!this.isHidden(numbered)) {
        numbered.textContent = this.templateStringValue.replace(this.placeholderTokenValue, index)
        index += 1
      }
    })
  }

  isHidden(element) {
    return element.offsetParent === null || getComputedStyle(element).display === "none"
  }
}
