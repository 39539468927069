export function prepareRecreatedDropEvent(event) {

  let files = []
  if (event.dataTransfer) {
    files = Array.from(event.dataTransfer.files)
  } else if (event.detail && event.detail.files) {
    files = event.detail.files
  } else if (event.target && event.target.files) {
    files = Array.from(event.target.files)
  }

  if (!files || files.length === 0) { return null }
  
  // Create custom event with the dataTransfer in the detail
  const customEvent = new CustomEvent('drop:recreated', {
    bubbles: true,
    detail: {
      files: files
    }
  })
  
  return customEvent
}