import * as UpChunk from '@mux/upchunk';

export default class ChunkedUploader {
  constructor(file, url, options = {}) {
    this.file = file;
    this.url = url;
    this.options = options;
    this.eventHandlers = {};
  }

  start() {
    this.uploader = UpChunk.createUpload({
      file: this.file,
      endpoint: this.url,
      chunkSize: this.options.chunkSize || 5120, // default chunk size in KB
    });

    // Register event handlers and store them to be detached later
    this.eventHandlers.onProgress = (progress) => {
      if (this.options.onProgress) {
        this.options.onProgress(progress);
      }
    };
    this.eventHandlers.onError = (error) => {
      if (this.options.onError) {
        this.options.onError(error);
      }
    };
    this.eventHandlers.onSuccess = () => {
      if (this.options.onSuccess) {
        this.options.onSuccess();
      }
    };

    this.uploader.on('progress', this.eventHandlers.onProgress);
    this.uploader.on('error', this.eventHandlers.onError);
    this.uploader.on('success', this.eventHandlers.onSuccess);
  }

  destroy() {
    if (this.uploader) {
      this.uploader.off('progress', this.eventHandlers.onProgress);
      this.uploader.off('error', this.eventHandlers.onError);
      this.uploader.off('success', this.eventHandlers.onSuccess);
    }
  }
}