import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    closeOnOverlayClick: Boolean,
  }


  static targets = ["drawer", "content"]

  openTargets = new Set()
  defaultClosedClasses = ["opacity-0", "translate-x-10", "pointer-events-none"]

  connect() {
    if (this.hasDrawerTarget) {
      if (this.drawerTarget.dataset.default === "open" || !this.drawerTarget.dataset.default) {
        this.open()
      } else {
        this.close()
      }
    }
  }

  disconnect() {
    this.removeEventListeners()
  }

  open() {
    this.drawerTarget.classList.remove(...this.defaultClosedClasses)
    document.addEventListener('click', this.outsideClickHandler)
    document.addEventListener('keydown', this.keydownHandler)
    this.openTargets.add(this.drawerTarget)
  }

  close(event) {
    this.drawerTarget.classList.add(...this.defaultClosedClasses)
    document.removeEventListener('click', this.outsideClickHandler)
    if (event) {
      event.preventDefault()
    }

    this.openTargets.delete(this.drawerTarget)
  }

  outsideClickHandler = (event) => {
    if (this.openTargets.has(event.target)) {
      this.close()
    }
  }

  keydownHandler = (event) => {
    if (event.key === "Escape") {
      this.close()
    }
  }

  removeEventListeners() {
    document.removeEventListener('click', this.outsideClickHandler)
    document.removeEventListener('keydown', this.keydownHandler)
  }
}
