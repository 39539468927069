import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { loaderThreshold: Number }

  connect() {
    this.element.addEventListener('submit', this.handleSubmit.bind(this))
  }

  handleSubmit(event) {
    const loaderThreshold = this.loaderThresholdValue
    console.log(loaderThreshold)


    const buttons = document.querySelectorAll(`[form=${this.element.id}].allow-loader`)

    this.timeout = setTimeout(() => {
      buttons.forEach((button) => {
        button.classList.add('button-loader')
      })
    }, loaderThreshold)

    event.target.addEventListener('ajax:complete', () => {
      buttons.forEach((button) => {
        button.classList.remove('button-loader')
      })
      clearTimeout(this.timeout)
    })
  }
}