import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["builder", "form", "preview", "iframe", 'loader', 'rerender']
  static values = {
    currentSection: String
  }

  connect() {
  }


  submitStart() {
    this.loaderTarget.classList.add('text-orange-500')
  }

  submitEnd() {
    this.loaderTarget.classList.remove('text-orange-500')
    this.loaderTarget.classList.add('text-green-500')
  }

  publish() {
    document.getElementById('publish_page').click()
  }

  scrollBuilder() {
    const frameUrl = new URL(this.builderTarget.src)
    const section = frameUrl.searchParams.get('section')

    if (section === this.currentSectionValue) {
      return // don't scroll to same section repeatedly
    }
    this.currentSectionValue = section

    const element = document.querySelector(`#builder_section_${section}`)
    window.scrollTo({
      top: element.offsetTop - 10,
      behavior: "smooth"
    })
  }


  scrollIframeTo(event) {
    const clicked = event.target
    const target = event.currentTarget

    if (['input', 'textarea', 'select'].includes(clicked.tagName.toLowerCase())) {
      return // don't scroll on input elements
    }
    const section = target.getAttribute('data-section')
    if (section === this.currentSectionValue) {
      return // don't scroll to same section repeatedly
    }

    this.currentSectionValue = section

    var iframe = this.iframeTarget

    const targetId = `#${section}`
    const offset = 0
    const targetElement = iframe.contentWindow.document.querySelector(targetId)

    if (targetElement) {
      const elementPosition = targetElement.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - offset

      iframe.contentWindow.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      })
    } else {
      console.error(`Element with selector "${targetId}" not found.`)
    }
  }

}