import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "hiddenDeformattedInput"]
  static values = {
    locale: String,
    currency: String,
    isCentesimal: Boolean
  }

  connect() {
    this.initFormatter()
    this.initHiddenDeformattedInput()

    this.format()

    if (this.input.hasAttribute("autofocus")) {
      this.placeCursorBeforeDecimal()
    }
  }

  disconnect() {
    this.teardownFormatter()
  }

  format(event) {
    this.withPreservedCursorPosition(() => {
      if (this.input.value === "-" || this.input.value.trim() === "") {
        return
      }

      const deformattedValue = this.deformat(this.input.value)
      this.input.value = this.formatCurrencyValue(deformattedValue)
      this.storeDeformattedAmountInHiddenInput(this.deformat(this.input.value))
    })
    this.input.dispatchEvent(new CustomEvent(`${this.identifier}:formatted`, { bubbles: true }))
    this.setEmptyAttribute()
  }

  withPreservedCursorPosition(formatOperationCallback) {
    // Save the current cursor position
    const input = this.input
    const start = input.selectionStart
    const end = input.selectionEnd
    const beforeValue = input.value

    // Execute the callback to format the input value
    formatOperationCallback()

    // If the input is not the active element, do nothing
    if (!this.inputHasFocus) { return }

    // If the user did a select all and then typed, let's assume they're typing a new number
    if (beforeValue.length <= 1) {
      this.placeCursorBeforeDecimal()
      return
    }

    const afterValue = input.value

    // Calculate the distance from the end of the input
    const distanceFromEnd = beforeValue.length - start

    // Calculate the new cursor position
    let newStart, newEnd
    if (afterValue.length >= beforeValue.length) {
      // If the input value has increased in length, keep the cursor in the same position relative to the end of the input
      newStart = afterValue.length - distanceFromEnd
      newEnd = newStart + (end - start)
    } else {
      // If the input value has decreased in length, keep the cursor in the same position
      newStart = start
      newEnd = end
    }

    // Ensure the new cursor position is within bounds
    newStart = Math.max(0, Math.min(newStart, afterValue.length))
    newEnd = Math.max(0, Math.min(newEnd, afterValue.length))

    // Set the new cursor position
    input.setSelectionRange(newStart, newEnd)
  }

  deformat(value) {
    const { currencySymbol } = this.formatterParts
    let { groupSymbol, decimalSymbol } = this.formatterParts

    // regex for normal letters and most symbols
    const regex = new RegExp(`[a-zA-Z!@#%^&*()_+{}\[\]:;<>?~\\=\\\\]`, 'g');

    let cleanedValue = value

    if (decimalSymbol == "," && groupSymbol == "." && !value.includes(currencySymbol)) {
      // assume it's already a deformatted value and switch decimal and group symbols
      [decimalSymbol, groupSymbol] = [groupSymbol, decimalSymbol]
    }

    cleanedValue = cleanedValue.replace(currencySymbol, '').replaceAll(regex, '').replaceAll(groupSymbol, '').replaceAll(decimalSymbol, '.')

    cleanedValue = removeExtraDecimalSymbols(cleanedValue, decimalSymbol)

    if (cleanedValue.length === 0) {
      return "0"
    }

    return cleanedValue;
  }

  storeDeformattedAmountInHiddenInput(deformattedValue) {
    if (!this.hasHiddenDeformattedInputTarget) { return }

    const { decimalSymbol } = this.formatterParts
    deformattedValue = deformattedValue.toString().replace(decimalSymbol, '.')

    if (this.isCentesimalValue) {
      deformattedValue = (parseFloat(deformattedValue) * 100)
    }

    this.hiddenDeformattedInputTarget.value = deformattedValue
  }

  initHiddenDeformattedInput() {
    if (this.hasHiddenDeformattedInputTarget) { return }

    const hiddenInput = document.createElement("input")
    hiddenInput.type = "hidden"
    hiddenInput.name = this.input.name
    hiddenInput.setAttribute(`data-${this.identifier}-target`, "hiddenDeformattedInput")
    this.element.appendChild(hiddenInput)
  }

  formatCurrencyValue(deformattedValue) {
    const { decimalSymbol, maximumFractionDigits } = this.formatterParts

    // split the value into before and after the decimal point, because the formatter rounds the decimals and we don't want that
    let [beforeDecimal, afterDecimal] = deformattedValue.split(decimalSymbol)

    // format before decimal point using the browser's own currency formatter
    let formattedValue = this.formatter?.format(beforeDecimal)

    // allow if the user typed a decimal point
    if (deformattedValue.includes(decimalSymbol)) {
      formattedValue += decimalSymbol
    }

    // add back any decimals, but don't allow more than 2
    if (afterDecimal?.length) {
      let decimalsLength = afterDecimal.length
      if (decimalsLength > maximumFractionDigits) {
        decimalsLength = maximumFractionDigits
      }
      formattedValue += afterDecimal.slice(0, decimalsLength).padEnd(decimalsLength, '0')
    }

    // if formattedValue had some weird characters, return 0
    if (formattedValue.includes("NaN")) {
      return 0
    }

    return formattedValue
  }

  placeCursorBeforeDecimal() {
    const { decimalSymbol } = this.formatterParts

    const cursorPosition = this.input.value.indexOf(decimalSymbol);
    if (cursorPosition !== -1) {
      this.input.setSelectionRange(cursorPosition, cursorPosition);
    } else {
      this.input.focus()
    }
  }

  get inputHasFocus() {
    return this.input === document.activeElement
  }

  get input() {
    if (this.hasInputTarget) { return this.inputTarget }
    return this.element
  }

  initFormatter() {
    this.formatter = Intl.NumberFormat(
      this.localeValue,
      {
        currency: this.currencyValue,
        style: "currency",
        currencyDisplay: "narrowSymbol",
        minimumFractionDigits: 0
      }
    )
  }

  get formatterParts() {
    const parts = this.formatter.formatToParts(12345.67);
    const currencySymbol = parts.find(part => part.type === 'currency')?.value;
    const groupSymbol = parts.find(part => part.type === 'group')?.value;
    const decimalSymbol = parts.find(part => part.type === 'decimal')?.value;

    const { maximumFractionDigits } = this.formatter.resolvedOptions()

    return { currencySymbol, groupSymbol, decimalSymbol, maximumFractionDigits }
  }

  setEmptyAttribute() {
    if (this.input.value === "") {
      this.input.setAttribute("data-empty", "true")
    } else {
      this.input.removeAttribute("data-empty")
    }
  }

  teardownFormatter() {
    this.formatter = null
  }
}

function removeExtraDecimalSymbols(string, decimalSymbol) {
  const firstDecimalSymbolIndex = string.indexOf(decimalSymbol)

  if (firstDecimalSymbolIndex !== -1) {
    const beforeDecimal = string.slice(0, firstDecimalSymbolIndex)
    let afterDecimal = string.slice(firstDecimalSymbolIndex + 1)

    afterDecimal = afterDecimal.replaceAll(decimalSymbol, '')

    return beforeDecimal + decimalSymbol + afterDecimal
  }

  return string
}