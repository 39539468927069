import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "bar", "text" ]

  static classes = [ "done" ]

  updateProgress(event) {
    const { progress } = event?.detail
    if (!progress) { return }

    this.element.classList.remove("hidden")

    this.updateBar(progress)
    this.updateText(progress)

    if (progress === 100 && this.hasDoneClass) {
      setTimeout(() => {
        this.element.classList.add(...this.doneClasses)
      }, 1000) // should be done differently
    }
  }

  updateBar(progress) {
    if (!this.hasBarTarget) { return }

    this.barTarget.style.width = `${progress}%`
  }

  updateText(progress) {
    if (!this.hasTextTarget) { return }

    this.textTarget.textContent = `${progress}%`
  }
}
