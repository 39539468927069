import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dropdown', 'form']

  connect() {
    this.stepId = this.element.dataset.stepId
    this.offersPath = this.element.dataset.offersPath
    this.loaded = false
  }

  loadDropdown() {
    if (this.loaded) return

    const frame = this.element.querySelector(`#offers_dropdown_${this.stepId}`)
    frame.src = this.offersPath
    this.loaded = true
  }

  toggleDropdown() {
    const isHidden = this.dropdownTarget.classList.contains('hidden')

    if (isHidden) {
      this.loadDropdown()
    }

    this.dropdownTarget.classList.toggle('hidden')
  }

  hideDropdown() {
    this.dropdownTarget.classList.add('hidden')
  }

  selectOfferBump(event) {
    event.preventDefault()

    const button = event.currentTarget
    const orderBumpId = button.dataset.orderBumpId

    // Find and select the corresponding radio input
    const radioInput = this.element.querySelector(`#stacks_step_offers_order_bump_ids_${orderBumpId}`)
    if (radioInput) {
      radioInput.checked = true

      // Submit the form
      const form = radioInput.closest('form')
      if (form) {
        form.requestSubmit()
      }

      // Hide the dropdown after selection
      this.hideDropdown()
    }
  }
}
