import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["target"]

  update(event) {
    event.preventDefault()

    const { value } = event.currentTarget

    this.targetTarget.value = value
  }
}
