import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dropzone" ]

  // use tailwind's named groups e.g. group/main-dropzone 
  // and group-data-[dragover]/main-dropzone:border-richBlack
  // or data-[dragover]:border-richBlack of the group element itself
  // to style the dropzone differently when dragging over it
  
  addDragoverDataAttribute(event) {
    event.preventDefault()
    this.dropzone.setAttribute('data-dragover', 'true')
  }

  removeDragoverDataAttribute(event) {
    event.preventDefault()
    this.dropzone.removeAttribute('data-dragover')
  }

  get dropzone() {
    if (this.hasDropzoneTarget) {
      return this.dropzoneTarget
    }

    return this.element
  }
}