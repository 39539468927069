import { Controller } from "@hotwired/stimulus"

// This controller let you create a post request for links.
// Same as button_to but you can use it inside a form, which you can't do with button_to.
// Usage: link_to(path, data: { controller: "link-via-post", action: "link-via-post#submit" })
export default class extends Controller {
  submit(event) {
    event.preventDefault()

    const form = document.createElement('form')
    form.method = 'POST'
    form.action = this.element.href

    const csrf = document.createElement('input')
    csrf.type = 'hidden'
    csrf.name = 'authenticity_token'
    csrf.value = document.querySelector('meta[name="csrf-token"]').content

    form.appendChild(csrf)
    document.body.appendChild(form)
    form.submit()
  }
}
