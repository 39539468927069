import { SuperSelectController } from "@bullet-train/fields"

export default class extends SuperSelectController {
  static classes = ["error"]

  formatState(opt) {
    const imageUrl = opt.element?.dataset.image
    const icon = opt.element?.dataset.icon
    const description = opt.element?.dataset.description

    let selectedIconHTML = prepareSelectedIcons()

    let imageHtml = "";
    if (imageUrl) {
      imageHtml = `<span class="super-select--option-image"><img src="${imageUrl}" /></span>`
    } else if (icon) {
      console.log(`icon`, icon)
      imageHtml = `<span class="super-select--option-image">${icon}</span>`
    }

    let sanitizedText = sanitizeHTML(opt.text).trim()
    if (sanitizedText === "") {
      sanitizedText = "&nbsp;"
    }


    if (!opt.element && opt.id != "") {
      selectedIconHTML = prepareCreateIcon()
      sanitizedText = `<span class="text-richBlack/36 dark:text-offWhite/50">Create</span> "${sanitizedText}"`
    }

    let textHTML = `<span class="super-select--option-label">${sanitizedText}</span>`
    if (description) {
      textHTML += `<span class="super-select--option-description">${sanitizeHTML(description)}</span>`
    }

    return jQuery(`
      <span class="super-select--option-row">
        ${selectedIconHTML}
        ${imageHtml}
        <span class="super-select--option-text">
         ${textHTML}
        </span>
      </span>
    `)
  }

  acquiesceError(event) {
    this.element.classList.remove(...this.errorClasses)
  }
}

// https://portswigger.net/web-security/cross-site-scripting/preventing
function sanitizeHTML(str) {
  return str.replace(/[^\w. ]/gi, function (c) {
    return '&#' + c.charCodeAt(0) + ';';
  });
};

// Adding these through css had some issues because of the filter urls in the svg, so adding via js
function prepareSelectedIcons() {
  return `
    <span class="super-select--option-checkbox super-select--option-checkbox-not-selected">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g filter="url(#a)" shape-rendering="crispEdges"><rect width="16" height="16" x="4" y="4" fill="#fff" rx="4"/><rect width="17" height="17" x="3.5" y="3.5" stroke="#0B051D" stroke-opacity=".12" rx="4.5"/></g><defs><filter id="a" width="22" height="22" x="1" y="2" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="1"/><feGaussianBlur stdDeviation="1"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.0196078 0 0 0 0 0.113725 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1264_2141"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_1264_2141" result="shape"/></filter></defs></svg>
    </span>
    <span class="super-select--option-checkbox super-select--option-checkbox-selected">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g filter="url(#a)"><rect width="16" height="16" x="4" y="4" fill="#9CE7C1" rx="4"/><rect width="17" height="17" x="3.5" y="3.5" stroke="#9CE7C1" rx="4.5"/></g><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m8.875 11.912 1.667 2.275 4.583-4.374"/><defs><filter id="a" width="22" height="22" x="1" y="2" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="1"/><feGaussianBlur stdDeviation="1"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.0196078 0 0 0 0 0.113725 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1264_2140"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_1264_2140" result="shape"/></filter></defs></svg>
    </span>
  `
}


function prepareCreateIcon() {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" class="mr-1">
  <path d="M8 5V8.5M8 8.5V12M8 8.5H4.5M8 8.5H11.5" stroke="#0B051D" stroke-opacity="0.64" stroke-width="1.5" stroke-linecap="round"/>
</svg>`
}