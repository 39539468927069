import { Controller } from "@hotwired/stimulus"

// allows the on-demand creation of a tooltip inside (or around) an element with the following attribute
const tooltipRequestedAttribute = "data-create-tooltip"
const tooltipContainerAttribute = "data-tooltip-container"

// integration with tooltip-visibility controller
const containerDataAction = "mouseenter->tooltip-visibility#show mouseleave->tooltip-visibility#hide focus->tooltip-visibility#show:capture blur->tooltip-visibility#hide:capture transitionend->tooltip-visibility#ariaHide"

const tooltipTemplate = `
  <div class="TOOLTIP_CLASSES" id="TOOLTIP_UNIQUE_ID">
    TOOLTIP_TEXT
    <div class="tooltip-arrow"></div>
  </div>
`

export default class extends Controller {
  static classes = ["tooltip"]

  createTooltipElement(text) {
    const html = tooltipTemplate.trim()
      .replace('TOOLTIP_CLASSES', this.tooltipClasses.join(' '))
      .replace('TOOLTIP_UNIQUE_ID', this.createUniqueId())
      .replace('TOOLTIP_TEXT', text)
    
    const template = document.createElement('template')
    template.innerHTML = html
    return template.content.firstElementChild
  }
  
  connect() {
    this.element.querySelectorAll(`[${tooltipRequestedAttribute}]`).forEach(element => {
      this.instantiateTooltipWithinOrAround(element)
    })
    this.observeForTooltipRequests()
  }

  disconnect() {
    this.teardownObserver()
  }

  observeForTooltipRequests() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        // Handle attribute changes
        if (mutation.type === 'attributes' && 
            mutation.attributeName === tooltipRequestedAttribute) {
          this.instantiateTooltipWithinOrAround(mutation.target)
        }
        
        // Handle new nodes
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              // Check if the new element itself needs a tooltip
              if (node.hasAttribute(tooltipRequestedAttribute)) {
                this.instantiateTooltipWithinOrAround(node)
              }
              // Check if any descendants need tooltips
              node.querySelectorAll(`[${tooltipRequestedAttribute}]`).forEach(element => {
                this.instantiateTooltipWithinOrAround(element)
              })
            }
          })
        }
      })
    })

    this.observer.observe(this.element, {
      attributes: true,           
      attributeFilter: [tooltipRequestedAttribute],
      childList: true,           // Watch for added/removed nodes
      subtree: true              
    })
  }

  instantiateTooltipWithinOrAround(element) {
    if (this.elementAllowsChildNodes(element)) {
      this.instantiateTooltipWithin(element)
    } else {
      this.instantiateTooltipAround(element)
    }
  }

  instantiateTooltipWithin(container) {
    // idempotency: if the element already has a tooltip container, don't instantiate another one
    if (container.hasAttribute(tooltipContainerAttribute)) { return }

    this.ensureRelativeOrAbsolutePositioningOf(container)
    
    const text = this.extractTooltipTextFrom(container)
    if (!text) { return }
    
    const tooltip = this.createTooltipElement(text)
    container.appendChild(tooltip)
    container.setAttribute(tooltipContainerAttribute, "")

    if (container.hasAttribute("data-tooltip-default-shown")) {
      container.dataset.action = `${container.dataset.action || ""} show-by-default->tooltip-visibility#show`
    } else {
      container.dataset.action = `${container.dataset.action || ""} ${containerDataAction}`
    }

    this.updateAriaAttributesOf(container, tooltip)

    // idempotency: We're done. Turbo restores will already have the tooltip added.
    // We can remove the attribute so that we don't try to add it again.
    container.removeAttribute(tooltipRequestedAttribute)

    if (container.hasAttribute("data-tooltip-default-shown")) {
      setTimeout(() => {
        container.dispatchEvent(new CustomEvent("show-by-default"))
      }, 100)
    }
  }

  instantiateTooltipAround(element) {
    const wrapper = this.wrapElementInSpan(element)

    this.moveAttributesFromElementToWrapper(element, wrapper)
    element.removeAttribute(tooltipRequestedAttribute) // no longer needed as we'll be instantiating within the wrapper

    this.instantiateTooltipWithin(wrapper)
  }

  wrapElementInSpan(element) {
    const wrapper = document.createElement("span")
    wrapper.classList.add("tooltip-generated-wrapper")

    element.parentNode.insertBefore(wrapper, element)
    wrapper.appendChild(element)

    return wrapper
  }

  ensureRelativeOrAbsolutePositioningOf(container) {
    const computedStyle = window.getComputedStyle(container);
    const position = computedStyle.position;

    // only modify if the element isn't already properly positioned
    if (position !== "absolute" && position !== "relative") {
      container.style.position = "relative"
    }
  }

  extractTooltipTextFrom(container) {
    return container.getAttribute("title") || container.getAttribute("aria-label")
  }

  updateAriaAttributesOf(container, tooltip) {
    container.setAttribute("aria-describedby", tooltip.id)
    tooltip.setAttribute("aria-hidden", "true")

    container.removeAttribute("aria-label")
    container.removeAttribute("title")
  }

  elementAllowsChildNodes(element) {
    return !(
      element instanceof HTMLInputElement ||
      element instanceof HTMLSelectElement ||
      element instanceof HTMLTextAreaElement ||
      element instanceof HTMLImageElement ||
      element instanceof HTMLBRElement ||
      element instanceof HTMLHRElement ||
      element instanceof SVGElement // we won't be creating tooltips on SVG elements
    )
  }

  teardownObserver() {
    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
    }
  }

  createUniqueId() {
    this.counter = ((this.counter || 0) + 1) % Number.MAX_SAFE_INTEGER
    return `tooltip-${Date.now()}${this.counter}`
  }

  moveAttributesFromElementToWrapper(element, wrapper) {
    const attributesToMove = [
      'aria-label',
      'title',
      'data-tooltip-placement',
      'data-tooltip-default-shown'
    ]

    attributesToMove.forEach(attr => {
      if (element.hasAttribute(attr)) {
        wrapper.setAttribute(attr, element.getAttribute(attr))
        element.removeAttribute(attr)
      }
    })
  }
}
