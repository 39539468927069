import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]
  static values = {
    selectedTabIndex: {
      type: Number,
      default: 0
    }
  }

  selectTab(event) {
    if (event.params && event.params.tabIndex !== undefined) {
      this.selectedTabIndex = event.params.tabIndex
      return
    }

    const tab = event.currentTarget
    const tabIndex = this.tabTargets.findIndex(target => target === tab)
    this.selectedTabIndex = tabIndex
  }
  
  selectedTabIndexValueChanged() {
    this.updateLayout()
  }

  focusOnPreviousTab(event) {
    this.focusOnAdjacentTab(-1, event)
  }

  focusOnNextTab(event) {
    this.focusOnAdjacentTab(+1, event)
  }

  focusOnAdjacentTab(increment, event) {
    event.preventDefault()
    const focusedTabIndex = this.tabTargets.findIndex(target => target === event.currentTarget)
    const adjacentTabIndex = focusedTabIndex + increment
    if (adjacentTabIndex >= 0 && adjacentTabIndex < this.tabTargets.length) {
      this.tabTargets[adjacentTabIndex].focus()
    }
  }

  updateLayout() {
    this.updateTabAriaAttributes() // styling is handled by the aria attributes
    this.updatePanelVisibility() // visibility is handled by the hidden attribute
  }

  updateTabAriaAttributes() {
    this.tabTargets.forEach((target, index) => {
      target.setAttribute("aria-selected", index === this.selectedTabIndex)
      target.setAttribute("tabindex", index === this.selectedTabIndex ? "0" : "-1")
    })
  }

  updatePanelVisibility() {
    this.panelTargets.forEach((target, index) => {
      target.hidden = this.selectedTabIndex !== index
    })
  }

  get selectedTabIndex() {
    return this.selectedTabIndexValue
  }

  set selectedTabIndex(value) {
    this.selectedTabIndexValue = value
  }
}
