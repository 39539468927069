import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "input", "previewElement" ]
  static values = { defaultText: "" }

  connect() {
    this.updatePreviewElement()
  }

  updatePreviewElement() {
    if (!this.hasInputTarget || !this.hasPreviewElementTarget) { return }

    if (this.inputTarget.value === "") {
      if (this.inputTarget.placeholder) {
        this.previewElementTarget.textContent = this.inputTarget.placeholder
      } else {
        this.previewElementTarget.textContent = this.defaultTextValue
      }
    } else {
      this.previewElementTarget.textContent = this.inputTarget.value
    }

    this.dispatch("preview-updated", { detail: { value: this.inputTarget.value } })
  }
}
