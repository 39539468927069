// app/javascript/controllers/smooth_scroll_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  scroll(event) {
    event.preventDefault()
    const targetId = event.currentTarget.getAttribute("data-target")
    const offset = parseInt(event.currentTarget.getAttribute("data-offset") || 0)
    const targetElement = document.querySelector(targetId)

    if (targetElement) {
      const elementPosition = targetElement.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      })
    } else {
      console.error(`Element with selector "${targetId}" not found.`)
    }
  }
}
