import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { loadStripe } from "@stripe/stripe-js"

export default class extends Controller {
  static targets = [
    "confirmationToken",
    "expressCheckout",
    "expressCheckoutWrapper",
    "elements",
    "address",
    "customerName",
    "flash",
    "form",
    "orderId",
    "priceId",
    "setupIntentId",
    "submit",
    "waitingIndicator"]
  static values = { amount: Number, currency: String, publishableKey: String, purchaseUrl: String, brandColor: String }

  #elements
  #stripe

  async connect() {
    this.#stripe = await loadStripe(this.publishableKeyValue)
    this.initializeElement()
    this.initializeExpressCheckout()
  }

  initializeExpressCheckout() {
    const expressCheckoutElement = this.#stripe.elements(
      {
        mode: 'setup',
        currency: this.currencyValue,
        paymentMethodCreation: "manual",
        setupFutureUsage: "off_session",
      }
    ).create('expressCheckout')

    const mounted = expressCheckoutElement.mount(this.expressCheckoutTarget)
    if (!mounted) {
      this.expressCheckoutWrapperTarget.classList.add("hidden")
    }
  }



  initializeElement() {
    // TODO: This should be passed in for re-use
    const appearance = {
      variables: {
        colorPrimary: "#0B051D",
        colorBackground: "#ffffff",
        colorText: "#30313d",
        colorDanger: "#df1b41",
      },
      rules: {
        '.Tab': {
          border: '1px solid #E0E6EB',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
        },
        ".AccordionItem:first-child": {
          borderRadius: "12px",
        },
        ".AccordionItem--selected": {
          border: `1px solid var(--content-primary, ${this.brandColorValue})`,
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
          backgroundColor: "var(--content-background-base, #F8F5F4)",
        },
      }
    }

    // TODO: This should be passed in for re-use
    const options = {
      appearance,
      mode: "setup",
      currency: this.currencyValue,
      paymentMethodOrder: ["card", "affirm", "klarna", "afterpay_clearpay"],
      paymentMethodCreation: "manual",
      setupFutureUsage: "off_session",
      layout: {
        type: "accordion",
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false
      }
    }

    this.#elements = this.#stripe.elements(options)

    const paymentElement = this.#elements.create("payment", { layout: options.layout })

    paymentElement.mount(this.elementsTarget)

    if (this.hasAddressTarget) {
      const addressElement = this.#elements.create('address', { mode: "shipping" })
      addressElement.mount(this.addressTarget)
      this.customerNameTarget.classList.add('hidden')
    }

  }

  async submit(event) {
    event.preventDefault()

    this.setLoading(true)

    try {
      const { error: submitError } = await this.#elements.submit()
      if (submitError) {
        // There were errors with the values in the submission
        this.handleError(submitError)
        return
      }

      const { confirmationToken, error } = await this.#stripe.createConfirmationToken({ elements: this.#elements })

      if (error) {
        this.handleError(error)
        return
      }

      this.confirmationTokenTarget.value = confirmationToken.id
      this.#submit()
    } catch (error) {
      this.handleError(error.message)
      this.setLoading(false)
    }
  }

  handleError(error) {
    this.flashTarget.classList.toggle("hidden", !error)
    this.flashTarget.textContent = error

    const elementsWrapper = this.elementsTarget
    elementsWrapper.scrollIntoView({ behavior: "smooth", block: "center" })

    this.setLoading(false)
  }

  async handleSubmit() {

    if (this.purchaseUrlValue) {
      const body = new FormData(this.formTarget)
      const options = { responseKind: "json" }

      const addressElement = this.#elements.getElement('address')

      if (addressElement) { // fill in the customer name if address is complete
        const { complete, value: { address: shipping_address, name } } = await addressElement.getValue()

        if (complete) {
          body.set('customer[name]', name)
          const shipping_address_params = new URLSearchParams(shipping_address)
          shipping_address_params.forEach((value, key) => body.append(`shipping_address[${key}]`, value))
        }
      }

      return await post(this.purchaseUrlValue, { body: body, ...options }).then(response => response.json)
    }

    return this.formTarget.submit()
  }

  setLoading(isLoading) {
    this.submitTargets.forEach(element => {
      element.disabled = isLoading
    })
    this.waitingIndicatorTargets.forEach(element => {
      element.classList.toggle("hidden", !isLoading)
    })
  }

  // Internal

  async #submit() {
    const result = await this.handleSubmit()
    if (result.success) {
      // TODO:Not safe passing the customer id like this but currently it needs to be in a signed cookie,
      // so we let the server handle it (redirects twice)
      window.location.href = result.next_step
    }
    else {
      if (result.next_action) {
        this.orderIdTarget.value = result.order.id
        this.priceId.value = result.price.id
        const { setupIntent: { id: setupIntentId } } = await this.#stripe.handleNextAction({ clientSecret: result.next_action.client_secret })
        this.confirmationTokenTarget.value = null
        this.setupIntentIdTarget.value = setupIntentId
        this.#submit()
      }
    }
  }
}
