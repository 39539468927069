import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  switchOff() {
    if (this.inputTarget.checked) {
      this.inputTarget.checked = false
      this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  switchOn() {
    if (!this.inputTarget.checked) {
      this.inputTarget.checked = true
      this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }
}
