import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    displayMode: String
  }

  connect() {
    this.applyTheme();
    if (this.displayModeValue === 'auto') {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.applyTheme.bind(this));
    }
  }

  applyTheme() {
    if (this.displayModeValue === 'dark') {
      document.documentElement.classList.add('dark');
    } else if (this.displayModeValue === 'auto') {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }
}