import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "emptyState", "itemList" ]

  static values = {
    requestMediaEventName: {
      type: String,
      default: "requestMedia"
    },
    showEmptyState: {
      type: Boolean,
      default: true
    }
  }

  connect() {
    this.toggleLayout()
  }

  toggleLayout() {
    this.emptyStateTarget.classList.toggle("hidden", !this.showEmptyStateValue)
    this.itemListTarget?.classList.toggle("sr-only", this.showEmptyStateValue) // use sr-only to still allow asset-pickers to display their modal dialogs
  }

  requestMedia() {
    this.dispatch(this.requestMediaEventNameValue)
  }

  updateLayout(event) {
    this.showEmptyStateValue = event?.detail?.showEmptyState || false
    this.toggleLayout()
  }
}