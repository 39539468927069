import { Controller } from "@hotwired/stimulus"
import {computePosition, autoUpdate, flip, shift, offset, arrow} from '@floating-ui/dom';

const tooltipContainerAttribute = "data-tooltip-container"

export default class extends Controller {
  static classes = ["showTooltip"] // mainly for transitions

  // look at data-action on root element for events hooking up these calls
  show(event) {
    this.toggleTooltipVisibility(event, true)
  }

  hide(event) {
    this.toggleTooltipVisibility(event, false)
  }

  // short-lived, only while tooltip is visible, see removePositionerFor below
  startPositionerFor(container, tooltip) {
    const arrowElement = tooltip.querySelector(".tooltip-arrow")
    tooltip.removePositioner = autoUpdate(container, tooltip, () => {
      this.toggleTransitionsOn(tooltip, false);
      
      computePosition(container, tooltip, {
        placement: container?.dataset?.tooltipPlacement || "top",
        middleware: [
          offset(5),
          flip(),
          shift({padding: 10}),
          arrow({element: arrowElement})
        ]
      }).then(({x, y, placement, middlewareData}) => {
        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        const transformOrigin = {
          top: 'bottom',
          right: 'left', 
          bottom: 'top',
          left: 'right'
        }[placement.split('-')[0]];

        Object.assign(tooltip.style, {
          transformOrigin: transformOrigin
        });

        const {x: arrowX, y: arrowY} = middlewareData.arrow;
      
        const [basePlacement, modifier] = placement.split('-');
        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[basePlacement];

        // Adjust arrow position based on start/end modifier
        let adjustedArrowX = arrowX;
        let adjustedArrowY = arrowY;

        if (modifier === 'start') {
          if (['top', 'bottom'].includes(basePlacement)) {
            adjustedArrowX = 16; // Closer to left edge
          } else {
            adjustedArrowY = 16; // Closer to top edge
          }
        } else if (modifier === 'end') {
          if (['top', 'bottom'].includes(basePlacement)) {
            adjustedArrowX = tooltip.offsetWidth - 16 - arrowElement.offsetWidth; // Closer to right edge
          } else {
            adjustedArrowY = tooltip.offsetHeight - 16 - arrowElement.offsetHeight; // Closer to bottom edge
          }
        }

        Object.assign(arrowElement.style, {
          left: adjustedArrowX != null ? `${adjustedArrowX}px` : '',
          top: adjustedArrowY != null ? `${adjustedArrowY}px` : '',
          right: '',
          bottom: '',
          [staticSide]: '-4px',
        });

        // if arrow is too close to edges, hide it
        const isArrowTooClose = (['top', 'bottom'].includes(basePlacement)) 
          ? (arrowX <= 4 || arrowX >= tooltip.offsetWidth - 4)
          : (arrowY <= 4 || arrowY >= tooltip.offsetHeight - 4);

        if (isArrowTooClose) {
          arrowElement.classList.add('tooltip-arrow-hidden');
        } else {
          arrowElement.classList.remove('tooltip-arrow-hidden');
        }

        // Force a reflow before re-enabling transitions
        tooltip.offsetHeight;
        this.toggleTransitionsOn(tooltip, true);
      });
    });
  }

  removePositionerFor(tooltip) {
    if (typeof tooltip.removePositioner === "function") {
      tooltip.removePositioner()
    }
  }

  toggleTooltipVisibility(event, show) {
    const { container, tooltip } = this.getTooltipAndContainer(event)
    if (!container || !tooltip) { return }

    if (show) {
      tooltip.removeAttribute("aria-hidden")
      this.startPositionerFor(container, tooltip)
      container.classList.add(...this.showTooltipClasses)
    } else {
      container.classList.remove(...this.showTooltipClasses)
      this.removePositionerFor(tooltip)
      // see ariaHideTooltip for why we don't set aria-hidden here
    }
  }

  // set aria-hidden after transitionend and opacity is 0
  ariaHide(event) {
    const { tooltip } = this.getTooltipAndContainer(event)
    if (!tooltip) { return }
    
    const computedStyle = window.getComputedStyle(tooltip)
    if (computedStyle.opacity === "0") {
      tooltip.setAttribute("aria-hidden", "true")
    }
  }

  getTooltipAndContainer(event) {
    const notFound = { container: null, tooltip: null }

    const container = event.currentTarget
    if (!container.hasAttribute(tooltipContainerAttribute)) { return notFound }

    const tooltipId = container.getAttribute("aria-describedby")
    if (!tooltipId) { return notFound }

    const tooltip = document.getElementById(tooltipId)
    if (!tooltip) { return notFound }

    return { container, tooltip }
  }

  toggleTransitionsOn(tooltip, enable) {
    if (enable) {
      tooltip.style.removeProperty('transition');
    } else {
      tooltip.style.transition = 'none';
    }
  }
}
