import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  changeSelection(event) {
    switch (event.type) {
      case "keydown":
        this.handleArrowKeys(event)
        this.preventSpacebarScroll(event)
        break
      case "keyup":
        this.handleSpace(event)
        break
    }
  }

  handleArrowKeys(event) {
    switch (event.key) {
      case "ArrowLeft":
        this.selectPrevious(event)
        break
      case "ArrowRight":
        this.selectNext(event)
        break
    }
  }

  handleSpace(event) {
    if (event.key === " ") {
      this.selectNext(event, true)
    }
  }

  preventSpacebarScroll(event) {
    if (event.key === " ") {
      event.preventDefault()
    }
  }

  selectPrevious(event, allowCycling = false) {
    const currentIndex = this.currentCheckedIndex
    if (currentIndex > 0) {
      this.inputTargets[currentIndex - 1].checked = true
    } else if (allowCycling) {
      this.inputTargets[this.inputTargets.length - 1].checked = true
    }
    event.preventDefault()
  }

  selectNext(event, allowCycling = false) {
    const currentIndex = this.currentCheckedIndex
    if (currentIndex < this.inputTargets.length - 1) {
      this.inputTargets[currentIndex + 1].checked = true
    } else if (allowCycling) {
      this.inputTargets[0].checked = true
    }
    event.preventDefault()
  }

  get currentCheckedIndex() {
    return this.inputTargets.findIndex(input => input.checked)
  }
}
