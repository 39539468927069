import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    interval: { type: Number, default: 10000 }
  }

  connect() {
    this.startPolling()
  }

  disconnect() {
    this.stopPolling()
  }

  startPolling() {
    this.timer = setInterval(() => {
      this.submitForm()
    }, this.intervalValue)
  }

  stopPolling() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  submitForm() {
    const form = this.element
    const submitButton = form.querySelector('button[type="submit"]')

    if (submitButton) {
      submitButton.disabled = true
    }

    form.requestSubmit()

    if (submitButton) {
      setTimeout(() => {
        submitButton.disabled = false
      }, 1000)
    }
  }
}
