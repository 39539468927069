/*
Usage example:
  <%= form.number_field :amount, data: { controller: "fields--currency", currency: "fr-FR" }, class: "text-7xl", maxlength: 11 %>
*/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.style.width = "100%";

    this.ABSOLUTE_MAX_INPUT_LENGTH = 12;
    this.MAX_INPUT_LENGTH = Math.max(parseInt(this.element.getAttribute("maxlength")) || 10, this.ABSOLUTE_MAX_INPUT_LENGTH);

    this.DEFAULT_FONT_SIZE = this.element.clientHeight / 24; // to approximate font size based on tailwind class
    this.SHRINK_INTERVAL = 0.5;
    this.MIN_FONT_SIZE = 2;


    this.locale = this.element.getAttribute("data-locale") || navigator.language;
    this.currency = this.element.getAttribute("data-currency") || "USD"; // Default to USD

    this.insertEditableTarget({ classes: this.element.classList });

    // hide the original input
    this.element.classList.add("hidden");
    this.hiddenField = this.element;

    // set up the new editable field
    this.editableFieldWrap = this.element.nextElementSibling;
    this.editableFieldWrap.style.fontSize = `${this.DEFAULT_FONT_SIZE}rem`;
    this.editableField = this.editableFieldWrap.querySelector("[contenteditable]");

    // add event listeners
    this.editableField.addEventListener("input", (event) => {
      const cleaned = event.target.textContent.trim().replace(/[^0-9.,]/g, '');
      event.target.textContent = cleaned;

      if (event.target.textContent.length > this.MAX_INPUT_LENGTH) {
        event.target.textContent = event.target.textContent.slice(0, this.MAX_INPUT_LENGTH);
        return
      }

      this.syncHiddenField();
      this.setWrapperFontSize();

      // only clean up the input if the event is an insertText and the data is a number
      if (event.data?.match(/^\d$/)) {
        this.cleanUpInput(event);
      }

      this.adjustCursor();
    });

    this.editableField.addEventListener("blur", this.cleanUpInput.bind(this));

    // initialize the hidden field
    this.editableField.textContent = this.formatter().format(this.element.value);
    this.syncHiddenField();
  }

  formatter = () => {
    // Show decimals based on the presence of the decimal separator
    const numOfDecimalDigits = this.editableField.textContent.split(this.getDecimalSeparator(this.locale))[1]?.length || 0;
    let minimumFractionDigits = numOfDecimalDigits <= 2 ? numOfDecimalDigits : 2

    if (!this.editableField.matches(":focus") && numOfDecimalDigits > 0) {
      minimumFractionDigits = 2;
    }

    return new Intl.NumberFormat(this.locale, {
      minimumFractionDigits,
      maximumFractionDigits: 2
    });
  };

  insertEditableTarget({ classes }) {
    console.log('insertEditableTarget', this.locale, this.currency)
    const formatter = new Intl.NumberFormat(this.locale, {
      style: "currency",
      currency: this.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    // Extract the currency symbol from the formatted string
    const symbol = formatter.formatToParts(0).find(part => part.type === "currency")?.value || "$";

    this.element.insertAdjacentHTML("afterend", `
      <div class="inline font-black text-richBlack px-2 outline-none border-none flex items-center -gap-1 transition-all duration-300 ${classes}">
        <span class="text-richBlack">${symbol}</span>
        <span contenteditable="true" class="currency-field flex-1 whitespace-nowrap text-richBlack outline-none border-none min-w-2">
          ${this.element.value}
        </span>
      </div>
    `);
  }

  getCurrencySymbol() {
    // Use Intl.NumberFormat to get the currency symbol
    const formatter = new Intl.NumberFormat(this.locale, { style: 'currency', currency: this.currency });
    const parts = formatter.formatToParts(1); // Format a sample value to get parts
    return parts.find(part => part.type === 'currency')?.value || '$'; // Fallback to '$' if no symbol found
  }

  syncHiddenField() {
    // Convert visible input to a raw numeric value
    const value = this.toNumber(this.editableField.textContent);
    this.hiddenField.value = isNaN(value) ? "" : value * 100;
  }

  adjustCursor() {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(this.editableField);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  cleanUpInput(e) {
    const value = this.toNumber(e.target.textContent);
    e.target.textContent = isNaN(value) ? this.formatter().format(0) : this.formatter().format(value);
    this.syncHiddenField();
    this.setWrapperFontSize();
  }

  toNumber(value) {
    const locale = this.element.getAttribute("data-currency") || navigator.language;

    // Get locale-specific separators
    const decimalSeparator = this.getDecimalSeparator(locale);
    const thousandSeparator = this.getThousandSeparator(locale);

    // Remove thousand separators and replace decimal separator with a dot
    const normalized = value.split(thousandSeparator).join('').replace(decimalSeparator, '.');

    // Get number of decimal places, defaulting to 0 if no decimals
    const decimalPlaces = normalized.split(decimalSeparator)[1]?.length || 0;

    // Parse as float and format with at most 2 decimal places
    const maxDecimalPlaces = Math.min(2, Math.max(0, decimalPlaces))
    return parseFloat(normalized).toFixed(maxDecimalPlaces);
  }

  getDecimalSeparator(locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(1.1);
    return parts.find(part => part.type === "decimal")?.value || ".";
  }

  getThousandSeparator(locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(1000);
    return parts.find(part => part.type === "group")?.value || ",";
  }

  setWrapperFontSize() {
    // Count digits only (ignore commas and spaces)
    const digitCount = this.editableField.textContent.replace(/[^0-9]/g, '').length;

    // only shrink every 3 characters
    if (digitCount % 3 === 0) {
      const newFontSize = this.DEFAULT_FONT_SIZE - (digitCount / 3) * this.SHRINK_INTERVAL;
      this.editableFieldWrap.style.fontSize = `${Math.max(newFontSize, this.MIN_FONT_SIZE)}rem`;
    }
  }
}
