import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    params: Array
  }

  updateParamsInUrl(event) {
    const frame = event.target
    const frameUrl = new URL(frame.src)
    const currentUrl = new URL(window.location.href)

    let urlChanged = false

    this.paramsValue.forEach(param => {
      const value = frameUrl.searchParams.get(param)
      if (value) {
        currentUrl.searchParams.set(param, value)
        urlChanged = true
      }
    })

    if (urlChanged) {
      history.replaceState({}, '', currentUrl.toString())
    }

    this.dispatch("page-url-changed", {detail: {url: currentUrl.toString()}})
  }
}

