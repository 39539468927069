import Dialog from '@stimulus-components/dialog'

export default class extends Dialog {
  open(event) {
    this.animationDirection = 'opening'
    this.rememberElementToFocusOnClose(event)
    super.open(event)
  }

  close(event) {
    this.animationDirection = 'closing'
    super.close(event)
    this.focusBackToElementActiveBeforeOpen()
  }

  rememberElementToFocusOnClose(event) {
    this.activeElement = document.activeElement !== document.body ? document.activeElement : event.currentTarget
  }

  focusBackToElementActiveBeforeOpen() {
    if (this.activeElement === undefined) { return }
    this.activeElement.focus()
  }

  handleAnimationEnd(event) {
    switch (this.animationDirection) {
      case 'opening':
        // element with [autofocus] gets focus automatically
        break
      case 'closing':
        this.focusBackToElementActiveBeforeOpen()
        break
    }
  }
}