import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: String
  }
  connect() {
    this.dispatchEventAndRemove()
  }

  dispatchEventAndRemove() {
    setTimeout(() => {
      if (this.hasEventNameValue) {
        this.element.dispatchEvent(new CustomEvent(this.eventNameValue, { bubbles: true }))
      }
      this.element.remove()
    }, 10)
  }
}