import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.rangeInputs = this.element.querySelectorAll('input[type="range"]')
    this.numberInputs = this.element.querySelectorAll('input[type="number"]')

    this.rangeInputs.forEach(input => {
      input.addEventListener('input', this.updateRanges.bind(this))
      this.updateSliderBackground(input, input.value)
    })

    this.numberInputs.forEach(input => {
      input.addEventListener('input', this.updateNumberInputs.bind(this))
    })
  }

  updateRanges(event) {
    const changedInput = event.target

    this.updateSliderBackground(changedInput, changedInput.value)
    // sync the number input with the range input
    const changedNumberInput = Array.from(this.numberInputs).filter(input => input.name == changedInput.name)
    if (changedNumberInput.length > 0) {
      this.updateNumberValue(changedNumberInput[0], changedInput.value)
    }

    const total = Array.from(this.rangeInputs).reduce((sum, input) => sum + parseFloat(input.value), 0)
    const excess = total - 100

    if (excess !== 0) {
      const otherInputs = Array.from(this.rangeInputs).filter(input => input.name !== changedInput.name)
      const adjustment = (excess / otherInputs.length)

      otherInputs.forEach(input => {
        this.updateSliderValue(input, Math.max(0, Math.min(100, parseFloat(input.value) - adjustment)))
        // Update the corresponding number input if it exists
        this.numberInputs.forEach(numberInput => {
          if (numberInput.name == input.name) {
            numberInput.value = input.value
          }
        })
      })
    }
  }

  updateNumberInputs(event) {
    const changedInput = event.target

    // sync the slider input with the number input
    const changedSliderInput = Array.from(this.rangeInputs).filter(input => input.name == changedInput.name)
    if (changedSliderInput.length > 0) {
      this.updateSliderValue(changedSliderInput[0], changedInput.value)
    }

    const total = Array.from(this.numberInputs).reduce((sum, input) => sum + parseFloat(input.value), 0)
    const excess = total - 100

    if (excess !== 0) {
      const otherInputs = Array.from(this.numberInputs).filter(input => input !== changedInput)
      const adjustment = excess / otherInputs.length

      otherInputs.forEach(input => {
        this.updateNumberValue(input, Math.max(0, Math.min(100, parseFloat(input.value) - adjustment)))
        // Update the corresponding range input if it exists
        this.rangeInputs.forEach(rangeInput => {
          if (rangeInput.name == input.name) {
            this.updateSliderValue(rangeInput, input.value)
          }
        })
      })
    }
  }

  updateSliderValue(input, value) {
    const roundedValue = Math.floor(value / 5) * 5
    input.value = roundedValue
    this.updateSliderBackground(input, roundedValue)
  }

  updateNumberValue(input, value) {
    const roundedValue = Math.floor(value / 5) * 5
    input.value = roundedValue
  }

  updateSliderBackground(element, value) {
    element.style.background = `linear-gradient(90deg, #9CE7C1 0%, #44C38F ${value}%, #ddd ${value}%, #ddd 100%)`;
  }
}