import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dropzone", "assetContainer", // layout targets
    "asset", "imageAssetTemplate", "videoAssetTemplate", // adding/removing assets from the asset container
    "fileInput" // upload field targets
  ]

  static values = {
    updateLayoutEventName: String,
    acceptedTypes: {
      type: Array,
      default: []
    },
    acceptMultiple: {
      type: Boolean,
      default: false
    }
  }

  selectMedia() {
    if (!this.hasFileInputTarget) { return }
    this.fileInputTarget.click()
  }

  addOrReplaceAsset(event) {
    let files = []
    if (event.type === "drop") {
      event.stopPropagation()
      files = this.filterFilesByAcceptedTypes(event.dataTransfer.files)
    } else if (event.type === "drop:recreated") {
      files = this.filterFilesByAcceptedTypes(event.detail.files)
    } else if (event.type === "change") {
      event.preventDefault()
      files = this.filterFilesByAcceptedTypes(event.target.files)
      event.target.value = '' // reset the file input
    } else {
      return
    }

    if (files.length === 0) { return }

    if (!this.acceptMultipleValue) {
      if (this.hasAssetTarget) {
        this.removeAsset()
      }

      files = files.slice(0, 1)
    }

    const createPreviewForFiles = this.previewTemplateAvailable
    if (createPreviewForFiles) {
      files.forEach((file) => {
        this.addAssetFromTemplate(file)
      })
    }

    this.dispatch("files-added", { detail: { files } })
  }

  filterFilesByAcceptedTypes(files) {
    return Array.from(files).filter((file) => {
      return this.acceptedTypesValue.includes(file.type.split("/")[0])
    })
  }

  get previewTemplateAvailable() {
    return this.hasImageAssetTemplateTarget || this.hasVideoAssetTemplateTarget
  }

  addAssetFromTemplate(file) {
    if (!this.hasImageAssetTemplateTarget && !this.hasVideoAssetTemplateTarget) { return }
    if (!this.hasAssetContainerTarget) { return }

    const template = file.type.startsWith("image/") ? this.imageAssetTemplateTarget : this.videoAssetTemplateTarget

    let assetElement = template.content.firstElementChild.cloneNode(true)

    // increment the id in hidden field name attributes
    assetElement.file = file

    this.assetContainerTarget.appendChild(assetElement)

    this.updateLayout()
  }

  removeAsset(event) {
    if (!this.hasAssetTarget) { return }
    this.assetTarget.remove()
    this.updateLayout()
  }

  updateLayout() {
    if (!this.hasUpdateLayoutEventNameValue) { return }
    this.dropzoneTarget.dispatchEvent(new CustomEvent(`${this.updateLayoutEventNameValue}`, { bubbles: true, cancelable: true, detail: { showEmptyState: !this.hasAssetTarget } }))
  }
}