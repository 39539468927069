import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // class="animate-appear-then-fade"
  // data-action="animationend->notification#remove"
  // inspired by: https://x.com/_swanson/status/1752404309784809900
  remove() {
    this.element.remove()
  }
}
