import { Controller } from "@hotwired/stimulus"
import fitty from "fitty"

export default class extends Controller {
  static targets = [ "container" ]
  static values = {
    minSize: { type: Number, default: 16 },
    maxSize: { type: Number, default: 96 },
  }

  connect() {
    this.plugin = fitty(this.container, {
      minSize: this.minSizeValue,
      maxSize: this.maxSizeValue,
    })
  }

  disconnect() {
    this.plugin.unsubscribe()
    this.plugin = undefined
  }

  refit(event) {
    this.plugin.fit()
  }

  debugFit(event) {
    console.log("debugFit", event)
  }

  get container() {
    if (this.hasContainerTarget) { return this.containerTarget }
    return this.element
  }
}