



export function supportTurnAnimations() {
  const documentElement = document.documentElement

  document.addEventListener('click', function (event) {
    if (event.target.matches("[rel]")) {
      if (['next', 'prev'].includes(event.target.getAttribute('rel'))) {
        documentElement.classList.add('turn-enabled');
      }
    }
    else {
      documentElement.classList.remove('turn-enabled');
    }
  });

  addPopstateListener();

  supportPrevAnimations();
}

function addPopstateListener() {
  window.addEventListener('popstate', function (event) {
    documentElement = document.documentElement
    documentElement.classList.add('turn-enabled');
    documentElement.classList.remove('turn-reverse');
  });
}

function supportPrevAnimations() {
  document.addEventListener('click', function (event) {
    documentElement = document.documentElement

    if (event.target.matches("[rel]") && event.target.getAttribute('rel') === 'prev') {
      documentElement.classList.add('turn-reverse');
    } else {
      documentElement.classList.remove('turn-reverse');
    }
  });
}

