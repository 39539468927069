import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  // Uses a CSS class so we can use TailwindCSS groups to toggle the sidebar state.
  // e.g. group-[.sidebar-collapsed]/layout:w-20
  // Best applied to the root html element to persist Turbo body replacements.
  static classes = ["sidebarCollapsed"]

  static values = {
    sidebarCollapsed: { type: Boolean, default: false }
  }

  // triggered by user action
  toggleSidebar() {
    this.sidebarCollapsedValue = !this.sidebarCollapsedValue
    this.recordUserPreferenceFromAction()
  }

  // triggered by one-time-dispatcher during page transition
  collapseSidebarUnlessInsistsExpanded() {
    if (!this.insistsSidebarExpanded) {
      this.sidebarCollapsedValue = true
    }
  }

  // triggered by one-time-dispatcher during page transition
  expandSidebarUnlessPrefersCollapsed() {
    if (!this.preferSidebarCollapsed) {
      this.sidebarCollapsedValue = false
      this.insistsSidebarExpanded = false
    }
  }

  sidebarCollapsedValueChanged() {
    this.toggleClass()
  }

  toggleClass() {
    if (this.sidebarCollapsedValue) {
      this.element.classList.add(this.sidebarCollapsedClass)
    } else {
      this.element.classList.remove(this.sidebarCollapsedClass)
    }
  }

  recordUserPreferenceFromAction() {
    if (this.userExpandedSidebarFromPageSuggestedCollapsed) {
      this.insistsSidebarExpanded = true
    } else if (this.userCollapsedSidebarFromPageSuggestedCollapsed) {
      this.insistsSidebarExpanded = false
      this.preferSidebarCollapsed = false
    } else {
      this.preferSidebarCollapsed = this.sidebarCollapsedValue
    }
  }

  get userExpandedSidebarFromPageSuggestedCollapsed() {
    return this.sidebarCollapsedValue === false && !this.preferSidebarCollapsed
  }

  get userCollapsedSidebarFromPageSuggestedCollapsed() {
    return this.sidebarCollapsedValue === true && this.insistsSidebarExpanded
  }

  get preferSidebarCollapsed() {
    return Cookies.get("prefer-sidebar-collapsed") === "true"
  }

  set preferSidebarCollapsed(prefers) {
    if (prefers) {
      Cookies.set("prefer-sidebar-collapsed", "true", { expires: 365, path: "/" })
      Cookies.remove("insists-sidebar-expanded")
    } else {
      Cookies.remove("prefer-sidebar-collapsed")
    }
  }

  get insistsSidebarExpanded() {
    return Cookies.get("insists-sidebar-expanded") === "true"
  }

  set insistsSidebarExpanded(insists) {
    if (insists) {
      Cookies.set("insists-sidebar-expanded", "true", { expires: 365, path: "/" })
      Cookies.remove("prefer-sidebar-collapsed")
    } else {
      Cookies.remove("insists-sidebar-expanded")
    }
  }
}
