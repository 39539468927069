import { Controller } from "@hotwired/stimulus"

const MONACO_VERSION = "0.52.0"

export default class extends Controller {
  static targets = [ "plainField", "codeField" ]

  static values = {
    language: {
      type: String,
      default: "javascript" // for all supported languages, see https://github.com/microsoft/monaco-editor/tree/main/src/basic-languages
    },
    themeLight: {
      type: String,
      default: "vs"
    },
    themeDark: {
      type: String,
      default: "vs-dark"
    },
    monacoConfig: {
      type: Object,
      default: {}
    }
  }

  connect() {
    this.loadEditorFramework()
    this.watchColorScheme()
  }

  disconnect() {
    this.teardownCodeEditor()
    this.unwatchColorScheme()
  }

  initCodeEditor() {
    this.codeEditor = window.monacoInstance.editor.create(this.codeFieldTarget, {
      value: this.plainFieldTarget.value,
      language: this.languageValue,
      ...this.editorConfig
    })

    this.codeEditor.onDidChangeModelContent((event) => {
      this.updatePlainField()
    }) // teardownCodeEditor should properly unregister this event handler
  }

  updatePlainField() {
    this.plainFieldTarget.value = this.codeEditor.getValue()
  }

  get editorConfig() {
    return {
      automaticLayout: true,
      theme: this.theme,
      minimap: { enabled: false },
      scrollBeyondLastLine: false,
      ...this.monacoConfigValue
    }
  }

  showPlainField() {
    this.plainFieldTarget.classList.remove("hidden")
    this.codeFieldTarget.classList.add("hidden")
  }

  teardownCodeEditor() {
    this.codeEditor?.dispose()
  }

  async loadEditorFramework() {
    if (!this.hasPlainFieldTarget || !this.hasCodeFieldTarget) {
      return
    }

    try {
      await ensureMonacoInstance();
      this.initCodeEditor();
    } catch (error) {
      console.error("Monaco failed to load:", error);
      this.showPlainField();
    }
  }

  watchColorScheme() {
    this.userPrefersDarkSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)')
    this.userPrefersDarkSchemeQuery.addEventListener('change', this.updateTheme.bind(this))
  }
  
  unwatchColorScheme() {
    if (this.userPrefersDarkSchemeQuery === undefined) { return }
    this.userPrefersDarkSchemeQuery.removeEventListener('change', this.updateTheme.bind(this))
    this.userPrefersDarkSchemeQuery = undefined
  }

  updateTheme() {
    this.codeEditor?.updateOptions({ theme: this.theme })
  }

  get theme() {
    return this.userPrefersDarkScheme ? this.themeDarkValue : this.themeLightValue
  }

  get userPrefersDarkScheme() {
    if (this.userPrefersDarkSchemeQuery === undefined) { return false }
    return this.userPrefersDarkSchemeQuery.matches
  }
}

// Function to load Monaco Editor from CDN, and cache it on the window object
const ensureMonacoInstance = () => {
  if (window.monacoInstance) {
    return Promise.resolve(window.monacoInstance);
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://cdn.jsdelivr.net/npm/monaco-editor@${MONACO_VERSION}/min/vs/loader.js`;
    script.onload = () => {
      require.config({ paths: { 'vs': `https://cdn.jsdelivr.net/npm/monaco-editor@${MONACO_VERSION}/min/vs` } });

      require(['vs/editor/editor.main'], (monaco) => {
        window.monacoInstance = monaco; // Cache Monaco globally
        resolve(monaco);
      });
    };
    script.onerror = reject;
    document.head.appendChild(script);
  });
};