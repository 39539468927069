import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["showcase", "footer"]

  connect() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.footerTarget.classList.remove('bottom-0');
          this.footerTarget.classList.add('bottom-[-100px]');
        } else {
          this.footerTarget.classList.remove('bottom-[-100px]');
          this.footerTarget.classList.add('bottom-0');
        }
      });
    });

    observer.observe(this.showcaseTarget);
  }
}