import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "initialHeaderTemplate", "sortHeaderTemplate" ]
  static values = {
    headerId: String
  }

  updateHeader(event) {
    const count = event?.detail?.count

    const header = document.getElementById(this.headerIdValue)
    
    if (!header) { return }

    if (count < 2) {
      header.innerHTML = this.initialHeaderTemplateTarget.innerHTML
    } else {
      header.innerHTML = this.sortHeaderTemplateTarget.innerHTML
    }
  }
}
